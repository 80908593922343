import React from 'react'
import Box from './box'

const Packed = () => {
  return (
    <div className='production'>
      <div className="grid9">
        <Box/>
      </div>
    </div>
  )
}

export default Packed
