import React from 'react'
 
const BLoglistef = () => {
  return (
    <div className="contentw">
     <div className="blog-preview">
    
    <h2>I'm</h2>
        <div className="animate-text">
        <span className="light-red">Chuks Nwakwue</span>
        <span className="light-red"> React DEVELOPER</span>
         <span className="light-red">Web DESIGNER</span>
        <span className="light-red">Vue Developer</span>
        
    </div>
         <p>I'm a Software Developer based in Lagos state, Nigeria. I build immersive and beautiful web through carefully crafted code and user-centric design.</p>
     <div className='gum'>
     <a href="https://www.facebook.com/chuks.nwakwue"><i className="fa-brands fa-facebook"></i></a>
     <a href="https://www.linkedin.com/in/chukwuebuka-nwakwue-73186025a"><i className=" fab fa-linkedin"></i></a>
      <a href="/"><i className="fa-brands fa-whatsapp"></i></a>
     <a href="Nwakwue(@Chukwue40442489):https://twitter.com/chukwue40442489?t=M8ZlivumlY2LGl6pNNu-Aw&s=08"><i className="fa-brands fa-twitter"></i></a>
        </div>
    </div>
    

 </div>
  )
}

export default BLoglistef
