import React from 'react'
import './boxz.css'
// import Program from './animations'

const Box = () => {
  return (
    <div className='grid2'>
    
      <span>HTML</span>
      <span>CSS</span>
      <span>JAVASCRIPT</span>
      <span>JQUERY</span>
      <span>REACT</span>
    </div>
    
  )
}

export default Box
