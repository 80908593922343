import React from 'react'
import Portfolio from './portfolio'

const AllPort = () => {
  return (
    <>
      <Portfolio/>
    </>
  )
}

export default AllPort
